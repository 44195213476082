import React from 'react';
import { Helmet } from "react-helmet"
import Layout from "../components/LayoutAlt.js"
import Privy from "../components/Privacy.js"
import Social from "../components/social.js"

const Privacy = () => {
    return(
        <Layout>
            <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>ReaCH | Privacy</title>
        <link rel="canonical" href="https://reachun.org/privacy" />
      </Helmet>
    </div>
    <div className=" h-32 sm:h-48">
      <h1 className="text-center text-blue-dark text-6xl sm:text-8xl font-bold mt-24 pt-4 sm:pt-2">
      Privacy Policy
      </h1>
    </div>
    <div className="text-blue-dark pb-20">
    <Privy />
    </div>
        <Social />
        </Layout>
    )
}

export default Privacy